import React, { useCallback, useEffect } from 'react';
import { SearchAddressAutoComplete, SearchAddressAutoCompleteShowChosen } from './style';
import { Input } from '../input';
import parse from 'autosuggest-highlight/parse';
import { Box, Grid, Typography } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';

const AddressSearchGoogle = ({dataEntry, onChange, clearData, label, useHelperText= false, showAddress = false, icon, ...rest}: any) => {
    const [address, setAddress] = React.useState(dataEntry?.address || '');
    const [addressObj, setAddressObj] = React.useState<any>(dataEntry?.addressObject || null);
    const [suggestions, setSuggestions] = React.useState([] as any);

    const {
        ready,
        value,
        suggestions: { data },
        setValue,
        clearSuggestions
    } = usePlacesAutocomplete({
        requestOptions: {
            locationRestriction: {
                south: 43.3650,
                west: 20.8335,
                north: 43.8009,
                east: 21.7535
            },
            componentRestrictions: {
                country: 'rs'
            },
            language: 'sr-Latn'
        },
        debounce: 300
    });

    React.useEffect(() => {
        setSuggestions(data || []);
    }, [data]);

    const handleSelectPrediction = (addressObj: any) => {
        getGeocode({
            placeId: addressObj?.place_id,
            language: 'sr-Latn'
        }).then(async (results) => {
            const { lat, lng } = getLatLng(results[0]);
            onChange({
                ...addressObj,
                lat,
                lng
            });
        });
    };


    useEffect(() => {
        if (!addressObj || !(addressObj as any)?.place_id) {
            return;
        }
        handleSelectPrediction(addressObj);
    }, [addressObj]);


   const handlerClearData = useCallback(() => {
        setAddressObj(null);
        setValue('');
        clearSuggestions();
        setAddress('');
        clearData();
    }, [setValue, clearSuggestions, setAddressObj, setAddress, clearData]);


    React.useEffect(() => {
        if (!dataEntry?.address) return;
        if(dataEntry && address &&  address === dataEntry?.address) return;
        setAddressObj(dataEntry?.addressObject || null);
        setAddress(dataEntry?.address  || dataEntry?.addressObject?.description || '');
    }, [dataEntry, addressObj, address]);

    useEffect(() => {
        return () => {
            setAddress('');
            setAddressObj(null);
            setSuggestions([] as any);
        };
    }, []);

    useEffect(() => {
        if(!dataEntry?.addressObject || !dataEntry?.address) {
            setAddress('');
            setAddressObj(null);
            setSuggestions([] as any);
        }
    }, [dataEntry]);


    return (
        <SearchAddressAutoCompleteShowChosen>
            <SearchAddressAutoComplete
                sx={{ width: 300 }}
                freeSolo
                disabled={!ready}
                disableClearable={false}
                getOptionLabel={(option: any) => {
                    return typeof option === 'string' ? option : option?.description;
                }}
                options={suggestions}
                includeInputInList
                filterSelectedOptions
                noOptionsText="No locations"
                inputValue={address}
                value={value ? value : addressObj}
                onInputChange={(_event, newInputValue, reason: any) => {
                    if (reason === 'clear') {
                        console.log('called clear');
                        handlerClearData();
                    } else {
                        setAddress(newInputValue);
                    }
                }}
                onChange={(_event, newValue: any) => {
                    setAddressObj(newValue);
                }}
                renderInput={(params: any) => (
                    <Input
                        {...params}
                        {...rest}
                        variant="outlined"
                        label={label || 'Pretraga'}
                        fullWidth
                        useHelperText={useHelperText || false}
                        icon={icon}
                        /*icon={{
                            icon: AddLocationAltIcon,
                            handler: ()=> {console.log('Add location'); }
                        }}*/
                        onChange={(e: any) => {
                            setValue(e.target.value);
                            setAddress(e.target.value);
                        }}
                    />
                )}
                renderOption={(props, option: any) => {
                    const matches =
                        option.structured_formatting.main_text_matched_substrings || [];

                    const parts = parse(
                        option.structured_formatting.main_text,
                        matches.map((match: any) => [match.offset, match.offset + match.length])
                    );

                    return (
                        <li {...props}>
                            <Grid container alignItems="center">
                                <Grid item sx={{ display: 'flex', width: 44 }}>
                                    <LocationOnIcon sx={{ color: 'text.secondary' }} />
                                </Grid>
                                <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                                    {parts.map((part, index) => (
                                        <Box
                                            key={index}
                                            component="span"
                                            sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                                        >
                                            {part.text}
                                        </Box>
                                    ))}
                                    <Typography variant="body2" color="text.secondary">
                                        {option.structured_formatting.secondary_text}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </li>
                    );
                }}
            />
            {showAddress && <>{dataEntry?.address}</>}
        </SearchAddressAutoCompleteShowChosen>
    );
};

export default AddressSearchGoogle;