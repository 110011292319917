export enum NotificationType {
    ORDER_FINISHED = 0x1,
    ORDER_ITEM_FINISHED = 0x2,
    ORDER_ABORTED = 0x3,
    ORDER_CUSTOMER_NOT_SHOW = 0x4,
    ORDER_SAW = 0x5,
    ORDER_CUSTOMER_NOT_SHOW_FINISH = 0x6,
    ORDER_REFETCH = 0x10,

    DRIVER_MADE_ORDER_BID = 0x7,
    DRIVER_ORDER_BID_FINISHED = 0x8,
    DRIVER_ORDER_BID_TERMINATE = 0x9
}

export enum MessageType {
    PING = 'PING',

    SHIFT_STATUS_CHANGED = 'SHIFT_STATUS_CHANGED',

    SHIFT_STATUS_START = 'SHIFT_STATUS_START',
    SHIFT_STATUS_STOP = 'SHIFT_STATUS_STOP',
    SHIFT_STATUS_PAUSE = 'SHIFT_STATUS_PAUSE',
    SHIFT_STATUS_RESUME = 'SHIFT_STATUS_RESUME',

    NEW_ORDER = 'NEW_ORDER',
    ORDER_ASSIGNED = 'ORDER_ASSIGNED',
    ORDER_PRICE_CHANGED = 'ORDER_PRICE_CHANGED',
    ORDER_ITEM_ADDED = 'ORDER_ITEM_ADDED',
    ORDER_ITEM_UPDATED = 'ORDER_ITEM_UPDATED',
    ORDER_PERSONAL_INFO_UPDATED = 'ORDER_PERSONAL_INFO_UPDATED',
    ORDER_UPDATED = 'ORDER_UPDATED',
    ORDER_DECLINED = 'ORDER_DECLINED',
    ORDER_READY_TO_PICK = 'ORDER_READY_TO_PICK',
    ORDER_ABORTED = 'ORDER_ABORTED',
    ORDER_CUSTOMER_NOT_SHOWED = 'ORDER_CUSTOMER_NOT_SHOWED',
    ORDER_DISPATCHER_HANDOVER = 'ORDER_DISPATCHER_HANDOVER',

    REFETCH_ORDERS = 'REFETCH_ORDERS',

    DISPATCHER_NOTIFICATION = 'DISPATCHER_NOTIFICATION',
    DRIVER_ORDER_FINISHED = 'DRIVER_ORDER_FINISHED',
    DRIVER_ORDER_ITEM_FINISHED = 'DRIVER_ORDER_ITEM_FINISHED',
    DRIVER_ORDER_ABORTED = 'DRIVER_ORDER_ABORTED',
    DRIVER_ORDER_CUSTOMER_NOT_SHOW = 'DRIVER_ORDER_CUSTOMER_NOT_SHOW',
    DRIVER_ORDER_SAW = 'DRIVER_ORDER_SAW',

    DRIVER_LOCATION = 'DRIVER_LOCATION',

    DRIVER_ORDER_BIDDING_CREATED = 'DRIVER_ORDER_BIDDING_CREATED',
    DRIVER_MADE_ORDER_BID = 'DRIVER_MADE_ORDER_BID',
    ORDER_BIDDING_FINISHED = 'ORDER_BIDDING_FINISHED',
    ORDER_BID_WON = 'ORDER_BID_WON'
}