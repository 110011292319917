import styled from 'styled-components';
import { Flex } from '../../../../assets/style';
import { Colors } from '../../../../theme/palette';
import ButtonIcon from '../../../../components/button/ButtonIcon';


export const SettingsOrdersRowContainer = styled(Flex)`
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 30px 30px 20px;
    border-bottom: 1px solid ${Colors.black}; 
`;

export const SettingsShiftRowPreviewContainer = styled(Flex)`
    flex-direction: column;
    flex: 2;
`;

export const SettingsOrdersRowActionContainer = styled(Flex)`
   
`;

export const SettingsOrdersPreviewLabel = styled(Flex)`
    font: normal normal 400 26px/32.5px "Readex Pro";
    margin-bottom: 10px;
    color: ${Colors.black};
`;

export const SettingsOrdersPreviewValue = styled(Flex)`
    font: normal normal 400 22px/27.5px "Readex Pro";
    color: ${Colors.grey};
`;

export const SettingsOrdersButtonIconAction = styled(ButtonIcon)`
    &&& {
        color: ${Colors.black};
        svg {
            width: 32px;
            height: 32px;
        }
    }
`;