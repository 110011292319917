import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { OrderViewContentContainer, OrderViewListContainer } from '../style';
import OrderItem from '../OrderItem';
import useGetOrdersQuery from '../../../../../../core/api/hooks/query/orders/useGetOrdersQuery';
import { deepEqual } from 'fast-equals';
import OrderViewContext from '../context/context';
import UiDataContext from '../../../../../../context/UiContext/context';
import OrderViewListHeader from '../OrderViewListHeader';
import EmptyList from '../EmptyList';
import { TUseOptimizeEventData, useOptimizeEventClick } from '../../../../../../hooks/useOptimizeEventClick';
import { useModal } from '../../../../../../hooks/useModal';
import ChangeOrderDeliveryPrice from '../../../modals/ChangeOrderDeliveryPrice';
import { OrderViewActionsListEvents } from '../d';
import OrderAddItem from '../../../modals/OrderAddItem';
import UpdateOrderInformation from '../../../modals/UpdateOrderInformation';
import PreviewOrder from '../../../modals/PreviewOrder';
import OrderAbort from '../../../modals/OrderAbort';
import SetToPending from '../../../modals/OrderSetToPending';
import OrderMakeActive from '../../../modals/OrderMakeActive';
import { useAppDispatch } from '../../../../../../store/hooks';
import { clearOrderFinished, setOrderFinished } from '../../../../../../store/app/appSlice';
import { NotificationType } from '../../../../../../components/appbar/appbarRight/Notifications/d';
import OrderCreateBidding from '../../../modals/OrderCreateBidding';

const OrderViewList = () => {
    const {
        setLoading,
        resetLoading,
        wsRemoveOrderMessages,
        orderFinishedMsg,
        orderMsgs,
        orderRetch,
        orderNotShow,
        orderAborted,
        orderShiftMsg,
        orderBidTerminate,
        orderBidFinished,
        orderBidDriverOffers,
        setRefetchDrivers,
        wsRemoveMessagesByMultiTypes
    } = useContext(UiDataContext);
    const [listData, setListData] = useState([] as any);
    const { filter, isNeedRefetch, clearNeedRefetch, requestOptions } = useContext(OrderViewContext);
    const refReqOptions = useRef({});
    const dispatch = useAppDispatch();
    const timeOut = useRef<null | NodeJS.Timeout>(null);
    const channel = new BroadcastChannel('redux_channel');

    const isFilter = useMemo(() => filter && Object.keys(filter).filter(x => !!filter[x])?.length, [filter]);

    const refetchDone = useCallback(() => {
        clearNeedRefetch();
    }, [clearNeedRefetch]);

    const _requestOptions = useMemo(() => {
        if (!deepEqual(requestOptions, refReqOptions.current)) refReqOptions.current = requestOptions;
        return refReqOptions.current;
    }, [requestOptions, refReqOptions]);

    const {
        data: listState,
        error,
        isFetching,
        isLoading,
        isFetched,
        refetch
    } = useGetOrdersQuery(_requestOptions, refetchDone);

    useEffect(() => {
        if (!isLoading || !isFetched) {
            resetLoading();
            return;
        }
        setLoading();
        return () => {
            resetLoading();
        };
    }, [setLoading, resetLoading, isLoading, isFetched]);

    useEffect(() => {
        if (!isNeedRefetch || isFetching || error) return;
        refetch();
    }, [isNeedRefetch, refetch, isFetching, error]);

    useEffect(() => {
        if (!listState) return;
        setListData([...(listState?.rows || [])].map((x: any, index: number) => ({
            ...x,
            index: index + 1
        })));
    }, [listState]);

    useEffect(() => {
        if (!orderFinishedMsg.length) return;
        dispatch(setOrderFinished(orderFinishedMsg));
        wsRemoveOrderMessages();
        timeOut.current = setTimeout(() => {
            refetch();
            dispatch(clearOrderFinished());
            setRefetchDrivers();
            channel.postMessage({ type: NotificationType.ORDER_FINISHED });
        }, 15000);
    }, [orderFinishedMsg]);

    useEffect(() => {
        if (!orderBidTerminate.length ) return;
        wsRemoveMessagesByMultiTypes([NotificationType.DRIVER_ORDER_BID_TERMINATE]);
        refetch();
        setRefetchDrivers();
        channel.postMessage({ type: NotificationType.DRIVER_ORDER_BID_TERMINATE});
    }, [orderBidTerminate]);

    useEffect(() => {
        if (!orderBidFinished?.length) return;
        wsRemoveMessagesByMultiTypes([NotificationType.DRIVER_ORDER_BID_FINISHED]);
        refetch();
        setRefetchDrivers();
        channel.postMessage({ type: NotificationType.DRIVER_ORDER_BID_FINISHED});
    }, [orderBidFinished]);

    useEffect(() => {
        if(!orderBidDriverOffers?.length) return;
        wsRemoveMessagesByMultiTypes([NotificationType.DRIVER_MADE_ORDER_BID]);
        setRefetchDrivers();
        channel.postMessage({ type: NotificationType.DRIVER_MADE_ORDER_BID});
    }, [orderBidDriverOffers]);

    useEffect(() => {
        if (!orderShiftMsg || !orderShiftMsg?.length) return;
        wsRemoveMessagesByMultiTypes([NotificationType.ORDER_SAW]);
        refetch();
        channel.postMessage({ type: NotificationType.ORDER_SAW });
    }, [orderShiftMsg]);

    useEffect(() => {
        if (!orderRetch || !orderRetch?.length) return;
        wsRemoveMessagesByMultiTypes([NotificationType.ORDER_REFETCH]);
        refetch();
    }, [orderRetch]);

    useEffect(() => {
        if (!orderMsgs || !orderMsgs.length) return;
        wsRemoveMessagesByMultiTypes([NotificationType.ORDER_ITEM_FINISHED]);
        refetch();
        setRefetchDrivers();
        channel.postMessage({ type: NotificationType.ORDER_ITEM_FINISHED });
    }, [orderMsgs]);

    useEffect(() => {
        if (!orderNotShow || !orderNotShow.length) return;
        wsRemoveMessagesByMultiTypes([NotificationType.ORDER_CUSTOMER_NOT_SHOW, NotificationType.ORDER_CUSTOMER_NOT_SHOW_FINISH]);
        refetch();
        setRefetchDrivers();
        channel.postMessage({ type: NotificationType.ORDER_CUSTOMER_NOT_SHOW });
        channel.postMessage({ type: NotificationType.ORDER_CUSTOMER_NOT_SHOW_FINISH });
    }, [orderNotShow]);

    useEffect(() => {
        if (!orderAborted || !orderAborted.length) return;
        wsRemoveMessagesByMultiTypes([NotificationType.ORDER_ABORTED]);
        refetch();
        setRefetchDrivers();
        channel.postMessage({ type: NotificationType.ORDER_ABORTED });
    }, [orderAborted]);

    useEffect(() => {
        return () => {
            if (timeOut.current) clearTimeout(timeOut.current);
        };
    }, []);


    const { openModal: openDialogOrderChangeDP, closeModal: closeDialogChangeDP } = useModal({
        modal: ChangeOrderDeliveryPrice,
        isActionsButtonsVisible: false,
        isCloseAction: false,
        noPadding: false
    });

    const { openModal: openDialogOrderAddItem, closeModal: closeDialogAddItem } = useModal({
        modal: OrderAddItem,
        isActionsButtonsVisible: false,
        isCloseAction: false,
        noPadding: false
    });


    const { openModal: openDialogOrderUpdateInfo, closeModal: closeDialogUpdateInfo } = useModal({
        modal: UpdateOrderInformation,
        isActionsButtonsVisible: false,
        isCloseAction: false,
        noPadding: false
    });


    const { openModal: openDialogPreviewOrder, closeModal: closeDialogPreviewOrder } = useModal({
        modal: PreviewOrder,
        isActionsButtonsVisible: false,
        isCloseAction: true,
        noPadding: false
    });


    const { openModal: openDialogAbortOrder, closeModal: closeDialogAbortOrder } = useModal({
        modal: OrderAbort,
        isActionsButtonsVisible: false,
        isCloseAction: true,
        noPadding: true
    });

    const { openModal: openDialogSetToPending, closeModal: closeDialogSetToPending } = useModal({
        modal: SetToPending,
        isActionsButtonsVisible: true,
        isCloseAction: true,
        noPadding: true
    });

    const { openModal: openDialogMakeActive, closeModal: closeDialogMakeActive } = useModal({
        modal: OrderMakeActive,
        isActionsButtonsVisible: true,
        isCloseAction: true,
        noPadding: true
    });


    const { openModal: openDialogCreateBidding, closeModal: closeDialogCreateBidding } = useModal({
        modal: OrderCreateBidding,
        isActionsButtonsVisible: true,
        isCloseAction: true,
        noPadding: true
    });


    const { onClickHandler } = useOptimizeEventClick({
        eventHandler(data: TUseOptimizeEventData) {

            if (data.action === OrderViewActionsListEvents.CHANGE_EDIT_PRICE) {
                if (data.id) {
                    openDialogOrderChangeDP({
                        id: data.id,
                        order: data.param && JSON.parse(data.param),
                        closeModal: closeDialogChangeDP
                    });
                    return;
                }
            }

            if (data.action === OrderViewActionsListEvents.ADD_STOP) {
                if (data.id) {
                    openDialogOrderAddItem({
                        id: data.id,
                        order: data.param && JSON.parse(data.param),
                        closeModal: closeDialogAddItem
                    });
                    return;
                }
            }

            if (data.action === OrderViewActionsListEvents.EDIT_ORDER_INFO) {
                if (data.id) {
                    openDialogOrderUpdateInfo({
                        id: data.id,
                        order: data.param && JSON.parse(data.param),
                        closeModal: closeDialogUpdateInfo
                    });
                    return;
                }
            }


            if (data.action === OrderViewActionsListEvents.PREVIEW_ORDER) {
                if (data.id) {
                    openDialogPreviewOrder({
                        id: data.id,
                        order: data.param && JSON.parse(data.param),
                        closeModal: closeDialogPreviewOrder
                    });
                    return;
                }
            }

            if (data.action === OrderViewActionsListEvents.ABORT_ORDER) {
                if (data.id) {
                    openDialogAbortOrder({
                        id: data.id,
                        order: data.param && JSON.parse(data.param),
                        closeModal: closeDialogAbortOrder
                    });
                    return;
                }
            }

            if (data.action === OrderViewActionsListEvents.SET_TO_PENDING) {
                if (data.id) {
                    openDialogSetToPending({
                        id: data.id,
                        order: data.param && JSON.parse(data.param),
                        closeModal: closeDialogSetToPending
                    });
                    return;
                }
            }

            if (data.action === OrderViewActionsListEvents.MAKE_ACTIVE) {
                if (data.id) {
                    openDialogMakeActive({
                        id: data.id,
                        order: data.param && JSON.parse(data.param),
                        closeModal: closeDialogMakeActive
                    });
                    return;
                }
            }

            if (data.action === OrderViewActionsListEvents.SET_TO_BIDDING) {
                if (data.id) {
                    openDialogCreateBidding({
                        id: data.id,
                        order: data.param && JSON.parse(data.param),
                        closeModal: closeDialogCreateBidding
                    });
                    return;
                }
            }
        }
    });


    return (
        <OrderViewContentContainer>
            <OrderViewListHeader />
            <OrderViewListContainer onClick={onClickHandler} data-action-root>
                {listData?.length ?
                    (listData || []).map((order: any, index: number) => <OrderItem order={order} key={index} />)
                    : <EmptyList isFilter={isFilter} />}
            </OrderViewListContainer>
        </OrderViewContentContainer>
    );
};

export default OrderViewList;