import React, { useCallback, useState } from 'react';
import FormikInputNumber from '../../../../../../../components/Formik/FormikInputNumber';
import { Translate } from '../../../../../../../internationalization/translate';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import NumbersIcon from '@mui/icons-material/Numbers';
import { useFormikContext } from 'formik';
import FormikInputTimeMask from '../../../../../../../components/Formik/FormikInputTimeMask';

const PickUpValue = ({ id, name = 'pickUpValue', ...rest }: any) => {

    const { setFieldValue } = useFormikContext() as any;
    const [state, setState] = useState<boolean>(false);
    
    const changeType = useCallback(()=> {
        setFieldValue(`${id ? `${id}_` :''}pickUpValue`, '', false);
        setState(v => !v);
    },[setState, setFieldValue]);

    if (!state) {
        return (
            <FormikInputNumber
                name={`${id ? `${id}_` :''}${name}`}
                variant={'outlined'}
                label={Translate.defineOrderItemPickUpValue}
                type={'number'}
                inputProps={{
                    step: '5'
                }}
                iconRight={{
                    icon: AccessTimeIcon,
                    handler: changeType,
                    tooltip: {
                        title: Translate.defineOrderItemPickUpValueUseTime
                    }
                }}
                {...rest}
            />
        );
    }
    ;


    return <FormikInputTimeMask
        name={`${id ? `${id}_` :''}${name}`}
        mask="00:00"
        placeholderChar={'_'}
        variant={'outlined'}
        label={Translate.defineOrderItemPickUpValue}
        iconRight={{
            icon: NumbersIcon,
            handler: changeType,
            tooltip: {
                title: Translate.defineOrderItemPickUpValueUseValue
            }
        }}
        {...rest}
    />;
};

export default PickUpValue;