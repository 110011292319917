import {
    SettingsShiftRowPreviewContainer,
    SettingsOrdersButtonIconAction,
    SettingsOrdersPreviewLabel,
    SettingsOrdersPreviewValue,
    SettingsOrdersRowActionContainer,
    SettingsOrdersRowContainer
} from './style';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';


const SettingsOrdersRow = ({
                               label,
                               value,
                               handleAction,
                               icon = EditIcon
                           }: any) => {
    const {t} = useTranslation();
    const Icon = icon;

    return (
        <SettingsOrdersRowContainer>
            <SettingsShiftRowPreviewContainer>
                <SettingsOrdersPreviewLabel>{t(label)}</SettingsOrdersPreviewLabel>
                <SettingsOrdersPreviewValue>{value}</SettingsOrdersPreviewValue>
            </SettingsShiftRowPreviewContainer>
            <SettingsOrdersRowActionContainer>
                <SettingsOrdersButtonIconAction onClick={handleAction}>
                    <Icon />
                </SettingsOrdersButtonIconAction>
            </SettingsOrdersRowActionContainer>
        </SettingsOrdersRowContainer>
    );
};

export default SettingsOrdersRow;